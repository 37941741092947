<template>
    <v-app>
        <v-container fluid class="d-flex align-center wrap-auth" :style="`background-image: url('${require('@/assets/images/verification-bg.jpg')}')`">
            <v-main>
              
                <v-row class="align-center justify-center flex-column py-10">
                    <v-col
                      cols="12"
                      sm="8"
                      md="6"
                      lg="6"
                    >
                        <v-card class="text-center" elevation="0" color="transparent" tile>
                            <h2 class="text-sm-h3 text-h4 mb-5">¿Eres mayor de 18 años?</h2>
                            <h6 class="text-sm-h5 text-h6 mb-10 font-italic">Al ingresar a este sitio usted acepta las <a href="/condiciones">Condiciones</a> y nuestra <a href="/politica-de-privacidad">Política de Privacidad</a></h6>
                            <p class="font-weight-thin mb-10">El consumo excesivo de bebidas alcohólicas puede tener efectos perjudiciales para la salud. Bebe con responsabilidad y modera tu consumo para cuidar de ti mismo y de tu bienestar.</p>
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="12" md="5" offset-md="1">
                                        <v-btn
                                            color="primary"
                                            :x-large="$vuetify.breakpoint.smAndUp"
                                            block
                                            @click="acceptWarning"
                                        >
                                            Sí, lo soy
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <v-btn
                                            :x-large="$vuetify.breakpoint.smAndUp"
                                            outlined
                                            block
                                            href="https://www.google.com/"
                                        >
                                            No, no lo soy
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

            </v-main>
        </v-container>
    </v-app>
</template>
<script>
export default {
    data() {
      return {
        
      }
    },
    methods: {
        acceptWarning() {
          sessionStorage.setItem('warning', 'yes')
          // Redirect to old url or home
          if (this.$router.currentRoute.query.redirect) {
              this.$router.push({ path: this.$router.currentRoute.query.redirect })
          } else {s
              this.$router.push({ name: 'home' })
          }
        }
    },
}
</script>
<style lang="scss">
.wrap-auth {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>